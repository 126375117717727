import { render, staticRenderFns } from "./Simulator.vue?vue&type=template&id=b6e6a1a0&scoped=true&"
import script from "./Simulator.vue?vue&type=script&lang=ts&"
export * from "./Simulator.vue?vue&type=script&lang=ts&"
import style0 from "./Simulator.vue?vue&type=style&index=0&id=b6e6a1a0&lang=scss&scoped=true&"
import style1 from "./Simulator.vue?vue&type=style&index=1&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b6e6a1a0",
  null
  
)

export default component.exports